.home {
  display: grid;
  position: relative;
  width: 100%;
  height: 100%;
  align-content: flex-start;
  justify-items: center;
  min-width: 0;
  min-height: 0;
  grid-template-columns:
    1fr minmax(0, var(--plsmc-wide-chunk)) var(--plsmc-viewport-gap)
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    var(--plsmc-viewport-gap) minmax(0, var(--plsmc-wide-chunk)) 1fr;
  padding: 0px;
}
:where(.home > *) {
  grid-column: 4;
}
@media (min-width: 768px) {
  :where(.home > *) {
    grid-column: 4;
  }
}
.navigationBar:global(.__wab_instance) {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0px;
  object-fit: cover;
  position: sticky;
  left: 0px;
  top: 0px;
  z-index: 1;
  background: #ffffff;
  box-shadow: 0px -8px 16px 8px #535353b0;
  grid-column-start: 1;
  grid-column-end: -1;
  padding: 16px;
}
.link__amih1 {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.text__c8Dj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #535353;
  font-weight: 600;
  min-width: 0;
}
@media (min-width: 768px) {
  .text__c8Dj {
    color: #535353;
  }
}
.img__hXz1O {
  object-fit: cover;
}
.img__hXz1O > picture > img {
  object-fit: cover;
}
.img__hXz1O > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.img__x0Wxk {
  object-fit: cover;
}
.img__x0Wxk > picture > img {
  object-fit: cover;
}
.img__x0Wxk > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.link__yteEq {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  color: #535353;
}
@media (min-width: 768px) {
  .link__yteEq {
    color: #535353;
    margin: 0px 10px;
  }
}
.link__sEvw2 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  color: #535353;
}
@media (min-width: 768px) {
  .link__sEvw2 {
    color: #535353;
    margin: 0px 10px;
  }
}
.link__mVJcg {
  position: relative;
  color: #535353;
}
@media (min-width: 768px) {
  .link__mVJcg {
    margin: 0px 10px;
  }
}
.link__v5UNt {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  color: #535353;
}
@media (min-width: 768px) {
  .link__v5UNt {
    color: #535353;
    margin: 0px 10px;
  }
}
.link__iW1V {
  position: relative;
  color: #535353;
}
@media (min-width: 768px) {
  .link__iW1V {
    margin: 0px 10px;
  }
}
.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  align-content: unset;
  background: linear-gradient(#00000080, #00000080),
    url("./images/zacGudakovHomesjpg.jpg") left 50% top 50% / cover repeat;
  grid-column-start: 1;
  grid-column-end: -1;
  padding: 128px 32px;
}
@media (min-width: 768px) {
  .hero {
    position: relative;
    left: auto;
    top: auto;
    background: linear-gradient(#00000080, #00000080),
      url("./images/zacGudakovHomesjpg.jpg") left 50% top 25% / cover repeat;
    height: 800px;
  }
}
.foreground2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1080px;
  align-self: center;
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 0;
}
.foreground2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.foreground2 > :global(.__wab_flex-container) > *,
.foreground2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.foreground2 > :global(.__wab_flex-container) > picture > img,
.foreground2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
@media (min-width: 768px) {
  .foreground2 {
    align-self: center;
    flex-grow: 1;
  }
}
@media (min-width: 768px) {
  .foreground2 > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: center;
  }
}
.freeBox__czMec {
  display: flex;
  position: relative;
  flex-direction: column;
  padding-bottom: 48px;
}
.freeBox__czMec > :global(.__wab_flex-container) {
  flex-direction: column;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.freeBox__czMec > :global(.__wab_flex-container) > *,
.freeBox__czMec > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__czMec > :global(.__wab_flex-container) > picture > img,
.freeBox__czMec
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
.h1__iYmXp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.h1__kaZhj {
  color: #ffffff;
}
@media (min-width: 768px) {
  .h1__kaZhj {
    text-align: center;
  }
}
.text__lVlFb {
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  line-height: 28px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
.services {
  display: grid;
  position: relative;
  align-content: center;
  justify-items: center;
  width: 100%;
  grid-column-start: 1;
  grid-column-end: -1;
  grid-template-columns:
    1fr minmax(0, var(--plsmc-wide-chunk)) var(--plsmc-viewport-gap)
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    var(--plsmc-viewport-gap) minmax(0, var(--plsmc-wide-chunk)) 1fr;
  padding: 40px 8px;
  margin: 0px;
}
:where(.services > *) {
  grid-column: 4;
}
@media (min-width: 768px) {
  .services {
    justify-items: center;
    align-content: center;
    justify-self: center;
    padding: 80px 8px;
    margin: 0px;
  }
}
@media (min-width: 768px) {
  :where(.services > *) {
    grid-column: 4;
  }
}
.h2__tRtu4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #535353;
  min-width: 0;
}
@media (min-width: 768px) {
  .h2__tRtu4 {
    width: 100%;
    justify-self: flex-start;
    color: #535353;
    grid-column-start: 2;
    grid-column-end: -2;
  }
}
.columns__jl8Bc {
  --plsmc-rc-col-gap: 0px;
}
.columns__jl8Bc {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 0;
  padding: 8px;
}
.columns__jl8Bc > :nth-child(1n + 1) {
  width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
}
@media (min-width: 768px) {
  .columns__jl8Bc {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-self: flex-start;
    grid-column-start: 2;
    grid-column-end: -2;
  }
}
@media (min-width: 768px) {
  .columns__jl8Bc > :nth-child(4n + 1) {
    width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
  }
}
@media (min-width: 768px) {
  .columns__jl8Bc > :nth-child(4n + 2) {
    width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
  }
}
@media (min-width: 768px) {
  .columns__jl8Bc > :nth-child(4n + 3) {
    width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
  }
}
@media (min-width: 768px) {
  .columns__jl8Bc > :nth-child(4n + 4) {
    width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
  }
}
.column__d6T6J {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  background: none;
  min-width: 0;
  padding: 8px;
}
.freeBox___7K1Q6 {
  display: flex;
  position: relative;
  width: 100%;
  height: 300px;
  max-width: 100%;
  background: url("./images/callumHillNgGkGzslIaMUnsplashjpg.jpg") top 50% left 50% / cover repeat;
  flex-direction: column;
  align-self: center;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox___7K1Q6 {
    align-items: center;
    justify-content: flex-end;
  }
}
.freeBox__uVkce {
  display: flex;
  position: absolute;
  width: 100%;
  height: 150px;
  max-width: 100%;
  background: #00000080;
  flex-direction: column;
  left: auto;
  top: 150px;
  z-index: 1;
  align-self: flex-end;
  right: -1px;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px;
}
.freeBox__uVkce > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 15px);
  height: calc(100% + 15px);
}
.freeBox__uVkce > :global(.__wab_flex-container) > *,
.freeBox__uVkce > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__uVkce > :global(.__wab_flex-container) > picture > img,
.freeBox__uVkce
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .freeBox__uVkce {
    max-width: none;
    left: 0px;
    width: 100%;
    right: -6px;
    min-width: 0;
  }
}
@media (min-width: 768px) {
  .freeBox__uVkce > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.h3__d6V7X {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ffffff;
  min-width: 0;
}
.text___6Dz9 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ffffff;
  min-width: 0;
}
.column__heawe {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox___1Gj5 {
  display: flex;
  position: relative;
  width: 100%;
  height: 300px;
  max-width: 100%;
  background: url("./images/torbjornHelgesenYlZoYiInbkUnsplashjpg.jpg") top 50% left 50% / cover repeat;
  flex-direction: column;
  align-self: center;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px;
}
.freeBox___1Gj5 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-width: 0;
  margin-top: calc(0px - 30px);
  height: calc(100% + 30px);
}
.freeBox___1Gj5 > :global(.__wab_flex-container) > *,
.freeBox___1Gj5 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___1Gj5 > :global(.__wab_flex-container) > picture > img,
.freeBox___1Gj5
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 30px;
}
.freeBox__huRhO {
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 100%;
  height: 150px;
  max-width: 100%;
  background: #05050580;
  left: 0px;
  top: 119px;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px;
}
.freeBox__huRhO > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 15px);
  height: calc(100% + 15px);
}
.freeBox__huRhO > :global(.__wab_flex-container) > *,
.freeBox__huRhO > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__huRhO > :global(.__wab_flex-container) > picture > img,
.freeBox__huRhO
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .freeBox__huRhO {
    max-width: none;
  }
}
@media (min-width: 768px) {
  .freeBox__huRhO > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: center;
  }
}
.h3__xdv3F {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ffffff;
  margin-top: calc(0px + 15px) !important;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  min-width: 0;
}
.text__c9Jb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ffffff;
  min-width: 0;
}
.column__zebnw {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__bHei7 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 300px;
  max-width: 100%;
  background: url("./images/themePhotosClOpYwfFm0Unsplashjpg.jpg") top 50% left 50% / cover repeat;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px;
}
.freeBox__bHei7 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-width: 0;
  margin-top: calc(0px - 30px);
  height: calc(100% + 30px);
}
.freeBox__bHei7 > :global(.__wab_flex-container) > *,
.freeBox__bHei7 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__bHei7 > :global(.__wab_flex-container) > picture > img,
.freeBox__bHei7
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .freeBox__bHei7 > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 10px);
    height: calc(100% + 10px);
  }
}
@media (min-width: 768px) {
  .freeBox__bHei7 > :global(.__wab_flex-container) > *,
  .freeBox__bHei7 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__bHei7 > :global(.__wab_flex-container) > picture > img,
  .freeBox__bHei7
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 10px;
  }
}
.freeBox__xNm7P {
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 100%;
  height: 150px;
  max-width: 100%;
  background: #00000080;
  left: 0px;
  top: 120px;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px;
}
.freeBox__xNm7P > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 15px);
  height: calc(100% + 15px);
}
.freeBox__xNm7P > :global(.__wab_flex-container) > *,
.freeBox__xNm7P > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__xNm7P > :global(.__wab_flex-container) > picture > img,
.freeBox__xNm7P
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .freeBox__xNm7P {
    top: 139px;
    max-width: none;
  }
}
@media (min-width: 768px) {
  .freeBox__xNm7P > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: center;
  }
}
.h3__zVj3 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ffffff;
  min-width: 0;
}
.text__pOAab {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ffffff;
  min-width: 0;
}
.column__hqpuM {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__swWw0 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 300px;
  max-width: 100%;
  background: url("./images/themePhotosKlby0NxseY8Unsplashjpg.jpg") top 50% left 50% / cover repeat;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px;
}
.freeBox__swWw0 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-width: 0;
  margin-top: calc(0px - 30px);
  height: calc(100% + 30px);
}
.freeBox__swWw0 > :global(.__wab_flex-container) > *,
.freeBox__swWw0 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__swWw0 > :global(.__wab_flex-container) > picture > img,
.freeBox__swWw0
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 30px;
}
.freeBox__yvTj9 {
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 100%;
  height: 150px;
  max-width: 100%;
  background: #00000080;
  left: 0px;
  top: 120px;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px;
}
.freeBox__yvTj9 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 15px);
  height: calc(100% + 15px);
}
.freeBox__yvTj9 > :global(.__wab_flex-container) > *,
.freeBox__yvTj9 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__yvTj9 > :global(.__wab_flex-container) > picture > img,
.freeBox__yvTj9
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .freeBox__yvTj9 {
    max-width: none;
  }
}
@media (min-width: 768px) {
  .freeBox__yvTj9 > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: center;
  }
}
.h3__nuTy {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ffffff;
  min-width: 0;
}
.text__dtdJm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ffffff;
  min-width: 0;
}
.aboutUs {
  display: grid;
  position: relative;
  align-content: flex-start;
  justify-items: center;
  width: 100%;
  grid-column-start: 1;
  grid-column-end: -1;
  grid-template-columns:
    1fr minmax(0, var(--plsmc-wide-chunk)) var(--plsmc-viewport-gap)
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    var(--plsmc-viewport-gap) minmax(0, var(--plsmc-wide-chunk)) 1fr;
  padding: 40px 8px;
}
:where(.aboutUs > *) {
  grid-column: 4;
}
@media (min-width: 768px) {
  .aboutUs {
    justify-items: center;
    align-content: center;
    background: #535353;
    padding: 80px 8px;
    margin: 0px;
  }
}
@media (min-width: 768px) {
  :where(.aboutUs > *) {
    grid-column: 4;
  }
}
.h2__ojf6W {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #535353;
  min-width: 0;
}
@media (min-width: 768px) {
  .h2__ojf6W {
    width: 100%;
    color: #ffffff;
    grid-column-start: 2;
    grid-column-end: -2;
  }
}
.columns___5PqdH {
  --plsmc-rc-col-gap: 0px;
}
.columns___5PqdH {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 0;
  padding: 8px;
}
.columns___5PqdH > :nth-child(1n + 1) {
  width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
}
@media (min-width: 768px) {
  .columns___5PqdH {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-column-start: 2;
    grid-column-end: -2;
  }
}
@media (min-width: 768px) {
  .columns___5PqdH > :nth-child(2n + 1) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 768px) {
  .columns___5PqdH > :nth-child(2n + 2) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
.column__r8WXc {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__qYoXn {
  display: block;
  position: relative;
  width: 100%;
  height: 300px;
  max-width: 100%;
  background: url("./images/pedroLastraNyvq2Juw4OUnsplashjpg.jpg") top 50% left 50% / cover repeat;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .freeBox__qYoXn {
    flex-grow: 1;
    flex-shrink: 0;
  }
}
.column___7Vtuc {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.text__vEqGg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-bottom: 0px;
  min-width: 0;
  display: block;
}
@media (min-width: 768px) {
  .text__vEqGg {
    color: #ffffff;
    display: block;
  }
}
.contactUs {
  display: grid;
  position: relative;
  align-content: center;
  justify-items: center;
  width: 100%;
  grid-column-start: 1;
  grid-column-end: -1;
  grid-template-columns:
    1fr minmax(0, var(--plsmc-wide-chunk)) var(--plsmc-viewport-gap)
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    var(--plsmc-viewport-gap) minmax(0, var(--plsmc-wide-chunk)) 1fr;
  padding: 40px 8px;
}
:where(.contactUs > *) {
  grid-column: 4;
}
@media (min-width: 768px) {
  .contactUs {
    justify-items: center;
    align-content: center;
    margin: 0px;
  }
}
@media (min-width: 768px) {
  :where(.contactUs > *) {
    grid-column: 4;
  }
}
.h2___2RWje {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #535353;
  min-width: 0;
}
@media (min-width: 768px) {
  .h2___2RWje {
    width: 100%;
    grid-column-start: 2;
    grid-column-end: -2;
  }
}
.columns__mv6Dp {
  --plsmc-rc-col-gap: 0px;
}
.columns__mv6Dp {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 0;
  padding: 8px;
}
.columns__mv6Dp > :nth-child(1n + 1) {
  width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
}
@media (min-width: 768px) {
  .columns__mv6Dp {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-self: flex-start;
    grid-column-start: 2;
    grid-column-end: -2;
  }
}
@media (min-width: 768px) {
  .columns__mv6Dp > :nth-child(3n + 1) {
    width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
  }
}
@media (min-width: 768px) {
  .columns__mv6Dp > :nth-child(3n + 2) {
    width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
  }
}
@media (min-width: 768px) {
  .columns__mv6Dp > :nth-child(3n + 3) {
    width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
  }
}
.column__josqL {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__ninho {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.h3__jArHl {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #535353;
  min-width: 0;
}
.link__mh7Mg {
  position: relative;
  align-self: flex-start;
  color: #535353;
}
.column__sxk8L {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .column__sxk8L {
    display: flex;
  }
}
.freeBox__z8He9 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.h3__yKblf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #535353;
  min-width: 0;
}
.link__jW6AH {
  position: relative;
  color: #535353;
  text-align: left;
  align-self: flex-start;
}
.column__a4Wz6 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox___4HjCc {
  display: block;
  position: relative;
  width: 100%;
  height: 300px;
  max-width: 100%;
  background: url("./images/manWithClipboardHub2A2Ba81Aa66Caef2F1Fe09F5397074647405635X0ResizeQ75Boxjpg.jpg") left 25% bottom 2.53% / auto auto repeat;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px;
}
.footer {
  display: grid;
  position: relative;
  align-content: flex-start;
  justify-items: center;
  width: 100%;
  background: #535353;
  grid-column-start: 1;
  grid-column-end: -1;
  grid-template-columns:
    1fr minmax(0, var(--plsmc-wide-chunk)) var(--plsmc-viewport-gap)
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    var(--plsmc-viewport-gap) minmax(0, var(--plsmc-wide-chunk)) 1fr;
  padding: 8px 0;
}
:where(.footer > *) {
  grid-column: 4;
}
.columns__rvonl {
  --plsmc-rc-col-gap: 0px;
}
.columns__rvonl {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 0;
  padding: 8px;
}
.columns__rvonl > :nth-child(1n + 1) {
  width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
}
@media (min-width: 768px) {
  .columns__rvonl {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: nowrap;
    min-width: 0;
  }
}
@media (min-width: 768px) {
  .columns__rvonl > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__powA2 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 768px) {
  .column__powA2 {
    align-items: center;
    justify-content: center;
  }
}
.text__pwUtx {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ffffff;
  text-align: center;
  min-width: 0;
}
@media (min-width: 768px) {
  .text__pwUtx {
    display: none;
  }
}
.freeBox__jhD6 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: center;
  min-width: 0;
  padding: 8px;
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #ffffff;
  align-self: center;
  width: 1em;
  flex-shrink: 0;
  height: 1em;
}
.text__s1CyH {
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ffffff;
  text-align: left;
  min-width: 0;
}
.text__lrztr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  color: #ffffff;
  min-width: 0;
  display: none;
}
@media (min-width: 768px) {
  .text__lrztr {
    display: block;
  }
}
